@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kalam&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  /*
  font-family: "Gloria Hallelujah", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:focus {
  outline: none;
}

img {
  display: block;
}

video:focus {
  outline: none;
}


